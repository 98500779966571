import dynamic from 'next/dynamic';

const MobileBetslipManager = dynamic(
    () =>
        import(
            /* webpackChunkName: "MobileBetslipManager" */ './MobileBetslipManager'
        ),
    { ssr: false }
);

export default MobileBetslipManager;
