import type { FC } from 'react';
import { memo } from 'react';
import { useReactiveVar } from '@apollo/client';
import cn from 'classnames';
import type { PaginationOptions } from 'swiper/types';

import Carousel from 'components/betting/Carousel';
import { Banner } from 'components/betting/Promo/Banners';
import ErrorBoundary from 'components/ErrorBoundary';
import PackIcon, { IconsPack } from 'components/PackIcon';
import Spinner from 'components/Spinner';
import { hasTopPromoTournamentTransitionVar } from 'layouts/BettingLayout/bettingLayoutState';
import type { AnalyticAttrs } from 'services/GoogleAnalytics';
import { AnalyticAttrsCtx, Category } from 'services/GoogleAnalytics';
import { BannerThemes } from '../types';
import BannerBlock from './BannerBlock';
import TopPromoTournamentBannerStub from './TopPromoTournamentBannerStub';
import useTopPromoTournamentBanner from './useTopPromoTournamentBanner';

import 'swiper/css/pagination';

interface Props {
    fallback: () => JSX.Element | null;
}

const ANALYTIC: AnalyticAttrs = {
    'data-category': Category.BannerTopTournamentSlider,
};

const TopPromoTournamentBanner: FC<Props> = ({ fallback }) => {
    const hasTopPromoTournamentTransition = useReactiveVar(
        hasTopPromoTournamentTransitionVar
    );

    const {
        bg,
        delay,
        cmsBanner,
        matches,
        isTablet,
        isDesktop,
        isSlider,
        close,
        onSlideChange,
        hasMatches,
        isLoadEnd,
        isStartFetchMore,
        containerStyles,
        isStub,
        allowSlidePrev,
    } = useTopPromoTournamentBanner();

    if (isStub) {
        return (
            <TopPromoTournamentBannerStub
                isSpinner={true}
                style={{
                    backgroundColor: hasTopPromoTournamentTransition
                        ? `${bg}`
                        : undefined,
                }}
            />
        );
    }

    if (!cmsBanner || !hasMatches) return fallback();

    const sliders = hasMatches
        ? matches.map((match) => (
              <div
                  key={match.id}
                  className={cn('mt-2 md:mt-4', containerStyles)}
              >
                  <Banner
                      banner={match}
                      bannerTheme={BannerThemes.TopPromoTournamentSlider}
                  />
              </div>
          ))
        : null;

    const spinner = (
        <div className="absolute right-14 top-1/3 z-10">
            <Spinner />
        </div>
    );

    return (
        <AnalyticAttrsCtx.Provider value={ANALYTIC}>
            <div
                style={{ backgroundColor: `${bg}` }}
                className={cn('relative mb-2 w-full', {
                    'pb-10 md:pb-6': isSlider,
                })}
            >
                <ErrorBoundary>
                    {cmsBanner && <BannerBlock cmsBanner={cmsBanner} />}
                    {sliders && (
                        <>
                            {isSlider ? (
                                <div className="relative">
                                    {!isLoadEnd && isStartFetchMore && spinner}
                                    <Carousel
                                        sliders={sliders}
                                        height={isTablet ? 136 : 184}
                                        delay={Number(delay)}
                                        withControls={false}
                                        pagination={getPaginationOptions()}
                                        spaceBetween={8}
                                        centeredSlides={
                                            isDesktop ? false : isLoadEnd
                                        }
                                        slideClassName={containerStyles}
                                        onSlideChange={onSlideChange}
                                        loop={isLoadEnd}
                                        allowSlidePrev={allowSlidePrev}
                                    />
                                </div>
                            ) : (
                                <div className="flex justify-center gap-2 md:px-4">
                                    {sliders}
                                </div>
                            )}
                        </>
                    )}
                    <CloseButton close={close} />
                </ErrorBoundary>
            </div>
        </AnalyticAttrsCtx.Provider>
    );
};

const CloseButton: FC<{ close: VoidFunction }> = ({ close }) => (
    <div
        className="absolute right-2 top-2 z-2 flex h-10 w-10 cursor-pointer items-center justify-center md:right-4 md:top-4"
        onClick={close}
    >
        <PackIcon
            id="close"
            pack={IconsPack.ActionIcons}
            className="fill-primary-white"
        />
    </div>
);

const getPaginationOptions = (): PaginationOptions | undefined => {
    const bulletBeforeClass = cn(
        'before:!block before:!h-3 before:!w-3 before:-translate-x-1/3 before:-translate-y-1/3'
    );

    return {
        clickable: true,
        bulletActiveClass: cn(
            '!scale-[2] !bg-primary-orange-toxic before:pointer-events-none'
        ),
        bulletClass: cn(
            'relative mx-1 h-1 w-1 cursor-pointer rounded-full bg-primary-white !opacity-100',
            bulletBeforeClass
        ),
        horizontalClass: cn(
            '!bottom-0 ml-2 flex h-6 !transform-none items-center [&>*:before]:content-[""]'
        ),
    };
};

export default memo(TopPromoTournamentBanner);
