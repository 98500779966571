import type { FC } from 'react';
import cn from 'classnames';

import Spinner from 'components/Spinner';
import { topBannerCn } from '../constants';

const TopBannersCarouselStub: FC = () => {
    return (
        <div
            className={cn(
                'relative mx-auto flex !w-[290px] items-center justify-center md:!w-full',
                topBannerCn
            )}
        >
            <Spinner />
        </div>
    );
};

export default TopBannersCarouselStub;
