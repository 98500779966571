import type { CSSProperties, FC } from 'react';
import { useReactiveVar } from '@apollo/client';
import cn from 'classnames';

import Spinner from 'components/Spinner';
import { hasTopPromoTournamentTransitionVar } from 'layouts/BettingLayout/bettingLayoutState';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { topBannerCn, transitionDuration } from '../constants';

const absoluteCn = cn('absolute bottom-0 left-0 w-full');
const transitionCn = `transition-transform duration-${transitionDuration}`;

interface Props {
    isSpinner?: boolean;
    style?: CSSProperties;
}

const TopPromoTournamentBannerStub: FC<Props> = ({ style, isSpinner }) => {
    const { from } = useResponsive();
    const isDesktop = from('md');

    const hasTopPromoTournamentTransition = useReactiveVar(
        hasTopPromoTournamentTransitionVar
    );

    return (
        <div
            style={style}
            className={cn(
                'relative flex w-full items-center justify-center',
                topBannerCn,
                hasTopPromoTournamentTransition
                    ? 'overflow-y-visible'
                    : 'overflow-y-hidden'
            )}
        >
            {isSpinner && (
                <>
                    <Spinner
                        className={cn('z-10', {
                            [`${transitionCn} translate-y-[60px] sm:translate-y-[77px] md:translate-y-[127px]`]:
                                hasTopPromoTournamentTransition,
                        })}
                    />

                    <div
                        style={style}
                        className={cn(
                            'h-[120px] sm:h-[156px] md:h-[130px]',
                            absoluteCn,
                            {
                                [`${transitionCn} translate-y-[120px] sm:translate-y-[156px] md:translate-y-[130px]`]:
                                    hasTopPromoTournamentTransition,
                            }
                        )}
                    />
                    {isDesktop && (
                        <div
                            style={style}
                            className={cn('h-[124px]', absoluteCn, {
                                [`${transitionCn} translate-y-[254px]`]:
                                    hasTopPromoTournamentTransition,
                            })}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default TopPromoTournamentBannerStub;
