import { useEffect, useRef, useState } from 'react';

import { hasTopPromoTournamentTransitionVar } from 'layouts/BettingLayout/bettingLayoutState';
import { transitionDuration } from '../constants';

interface Input {
    isBanner: boolean;
    isNotValidBanner: boolean;
}

interface Output {
    isStub: boolean;
}

const useAnimationStub = ({ isBanner, isNotValidBanner }: Input): Output => {
    const [isStub, setIsStub] = useState<boolean>(true);

    const timeOutRef = useRef<number>();

    useEffect(
        () => () => {
            hasTopPromoTournamentTransitionVar(false);
        },
        []
    );

    useEffect(() => {
        if (isNotValidBanner) {
            setIsStub(false);

            return;
        }

        if (isBanner && isStub) {
            hasTopPromoTournamentTransitionVar(true);

            timeOutRef.current = window.setTimeout(() => {
                hasTopPromoTournamentTransitionVar(false);
                setIsStub(false);
            }, transitionDuration);
        }

        return () => clearTimeout(timeOutRef.current);
    }, [isBanner, isStub, isNotValidBanner]);

    return {
        isStub,
    };
};

export default useAnimationStub;
