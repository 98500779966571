import dynamic from 'next/dynamic';

const BetslipPersistUpdating = dynamic(
    () =>
        import(
            /* webpackChunkName: "BetslipPersistUpdating" */ './BetslipPersistUpdating'
        ),
    { ssr: false }
);

export default BetslipPersistUpdating;
