import type { FC } from 'react';
import { memo } from 'react';
import cn from 'classnames';

import { Color, Size } from 'components/Button/types';
import type { CmsBanner as CmsBannerType } from 'types/gql.cms';
import CmsBanner from '../../Banners/CmsBanner';

interface Input {
    cmsBanner: CmsBannerType;
}

const BannerBlock: FC<Input> = ({ cmsBanner }) => {
    const { buttonText, title, subtitle } = cmsBanner;

    const textBlock = (
        <div
            className={cn(
                'mb-3 mr-10 mt-2 flex flex-col font-bold leading-[1.1rem] typo-base sm:mb-4 sm:mr-6 sm:leading-6 sm:typo-lg-1 md:mt-0',
                { 'md:typo-lg-1 xl:typo-xl-1': !buttonText }
            )}
        >
            <span className={cn('line-clamp-2 font-klapt text-primary-white')}>
                {title}
            </span>
            <span className="line-clamp-2 font-klapt text-primary-orange-toxic">
                {subtitle}
            </span>
        </div>
    );

    const options = {
        textBlock,
        contentClassName: cn(
            'pointer-events-none col-start-2 col-end-4 row-span-full ml-4 flex flex-col items-start justify-center sm:col-end-3 md:ml-0'
        ),
        imageClassName: cn(
            'col-span-full row-span-full bg-cover bg-center bg-no-repeat'
        ),
        buttonOptions: {
            className: cn(
                'pointer-events-auto w-[160px] rounded-default sm:mt-1 xl:w-[200px]'
            ),
            size: Size.Medium,
            color: Color.Orange,
        },
    };

    return (
        <CmsBanner
            banner={cmsBanner}
            options={options}
            className={cn(
                'grid h-[128px] grid-cols-3 sm:h-[164px] sm:grid-cols-2 md:h-[176px]'
            )}
            isImageBackground={true}
        />
    );
};

export default memo(BannerBlock);
