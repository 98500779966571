import { useCallback, useEffect, useMemo } from 'react';
import cn from 'classnames';
import { setCookies } from 'cookies-next';
import { Swiper } from 'swiper/types';

import { CookiesType } from 'app-constants';
import { BannerZoneLocation } from 'components/betting/Promo/BannerZones/constants';
import { isHideTopPromoTournamentVar } from 'layouts/BettingLayout/bettingLayoutState';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import { SportEventsInList } from 'types';
import type { CmsBanner } from 'types/gql.cms';
import { Banner } from '../Banners/types';
import useBannerZoneData from '../useBannerZoneData';
import { getTopPromoBannerPlatform } from '../utils';
import useAnimationStub from './useAnimationStub';
import useTournamentMatches from './useTournamentMatches';

const pageLocations = [BannerZoneLocation.TOP_PROMO_TOURNAMENT_SLIDER];

interface Output {
    isTablet: boolean;
    isDesktop: boolean;
    isSlider: boolean;
    hasMatches: boolean;
    cmsBanner?: CmsBanner;
    matches: SportEventsInList;
    delay: number;
    bg?: string;
    close: VoidFunction;
    onSlideChange: (swiper: Swiper) => void;
    isLoadEnd: boolean;
    isStartFetchMore: boolean;
    containerStyles: string;
    isStub: boolean;
    allowSlidePrev: boolean;
}

const useTopPromoTournamentBanner = (): Output => {
    const { until, from } = useResponsive();

    const isMobile = until('sm');
    const isTablet = until('md');
    const isDesktop = from('xl');

    const platforms = useMemo(
        () => getTopPromoBannerPlatform(isMobile),
        [isMobile]
    );

    const {
        bg,
        delay,
        banners,
        maxCount: maxCountString,
        loading: bannerZoneDataLoading,
    } = useBannerZoneData({
        variables: {
            pageLocations,
            platforms,
        },
        fetchPolicy: 'cache-and-network',
    });

    const maxCount = Number(maxCountString);

    const initialLimit: number = useMemo(() => {
        if (isMobile) return 3;

        if (isDesktop) return 5;

        return 4;
    }, [isDesktop, isMobile]);

    const cmsBanner = banners.find(isCmsBanner);

    const {
        hasMatches,
        onSlideChange,
        matches,
        isLoadEnd,
        isStartFetchMore,
        tournamentSportEventLoading,
        allowSlidePrev,
    } = useTournamentMatches({
        isCmsBanner: !!isCmsBanner,
        initialLimit,
        isDesktop,
        maxCount,
        banners,
    });

    const matchCount = matches.length;

    const isBanner = Boolean(cmsBanner && matchCount);

    const isNotValidTournament =
        !matchCount && !tournamentSportEventLoading && !bannerZoneDataLoading;

    const isNotValidCmsBanner = !cmsBanner && !bannerZoneDataLoading;

    const isNotValidBanner = isNotValidTournament || isNotValidCmsBanner;

    const { isStub } = useAnimationStub({
        isBanner,
        isNotValidBanner,
    });

    useEffect(() => {
        if (!isNotValidBanner) {
            return;
        }

        isHideTopPromoTournamentVar(true);
    }, [isNotValidBanner]);

    const close = useCallback(() => {
        setCookies(CookiesType.HidePromoTournamentBanner, true);

        isHideTopPromoTournamentVar(true);
    }, []);

    const isSlider = useMemo(
        () => getIsSlider(isMobile, isDesktop, matchCount),
        [isMobile, isDesktop, matchCount]
    );

    const containerStyles = useMemo(
        () =>
            cn(
                'relative flex !w-[260px] justify-center',
                isSlider
                    ? 'mb-8 md:mb-6 md:!w-[320px]'
                    : 'mb-2 md:mb-4 md:!w-full'
            ),
        [isSlider]
    );

    return {
        isTablet,
        isDesktop,
        isSlider,
        bg,
        delay,
        close,
        onSlideChange,
        cmsBanner,
        matches,
        hasMatches,
        isLoadEnd,
        isStartFetchMore,
        containerStyles,
        isStub,
        allowSlidePrev,
    };
};

const isCmsBanner = (banner: Banner): banner is CmsBanner =>
    banner.__typename === 'DecodedBannerImpl';

const getIsSlider = (
    isMobile: boolean,
    isDesktop: boolean,
    matchCount: number
): boolean => {
    if (isMobile) return matchCount > 1;

    if (isDesktop) return matchCount > 3;

    return matchCount > 2;
};

export default useTopPromoTournamentBanner;
