import React, { FC, memo } from 'react';
import cn from 'classnames';

import Carousel from 'components/betting/Carousel';
import { Banner } from 'components/betting/Promo/Banners';
import { BannerZoneLocation } from 'components/betting/Promo/BannerZones/constants';
import ErrorBoundary from 'components/ErrorBoundary';
import { useResponsive } from 'layouts/MainLayout/ResponsiveLayoutContext';
import type { AnalyticAttrs } from 'services/GoogleAnalytics';
import { AnalyticAttrsCtx, Category } from 'services/GoogleAnalytics';
import { SportEventType } from 'types/gql.bet';
import { topBannerCn } from '../constants';
import { BannerThemes } from '../types';
import useBannerZoneData from '../useBannerZoneData';
import { loadPrevNextImages, onInit } from '../utils';
import TopBannersCarouselStub from './TopBannersCarouselStub';

const ANALYTIC: AnalyticAttrs = {
    'data-category': Category.BannerTopSlider,
};

interface Props {
    bannerZoneLocation?: BannerZoneLocation;
    withControls?: boolean;
    analyticData?: AnalyticAttrs;
    paddingBottomStyle?: boolean | string;
}

const TopBannersCarousel: FC<Props> = ({
    bannerZoneLocation = BannerZoneLocation.TOP_SLIDER,
    withControls = true,
    analyticData,
    paddingBottomStyle = 'mb-4 md:mb-2',
}) => {
    const { until } = useResponsive();

    const isMobile = until('md');

    const { delay, banners, loading } = useBannerZoneData({
        variables: {
            pageLocations: [bannerZoneLocation],
        },
    });

    const filteredBanners = banners.filter((banner) => {
        const isCmsBanner = banner.__typename === 'DecodedBannerImpl';

        return (
            (banner.__typename === 'BannerBettingSportEvent' &&
                banner.sportevent.fixture.type === SportEventType.Match) ||
            isCmsBanner
        );
    });
    const containerStyles = cn(
        'relative flex !w-[290px] justify-center',
        'md:!w-full'
    );

    if (loading) {
        return <TopBannersCarouselStub />;
    }

    if (!filteredBanners.length) return null;

    if (filteredBanners.length === 1) {
        return (
            <div
                className={cn(
                    'mx-auto flex justify-center overflow-hidden',
                    topBannerCn,
                    containerStyles
                )}
            >
                <Banner
                    banner={filteredBanners[0]}
                    bannerTheme={BannerThemes.TopSlider}
                />
            </div>
        );
    }

    const sliders = filteredBanners.map((banner, index) => (
        <div key={banner.id} className={containerStyles}>
            <Banner
                banner={banner}
                bannerTheme={BannerThemes.TopSlider}
                options={{ isFirstBanner: index === 0 }}
            />
        </div>
    ));

    return (
        <AnalyticAttrsCtx.Provider value={analyticData || ANALYTIC}>
            <div
                className={cn(
                    'relative z-0 w-full md:block',
                    paddingBottomStyle
                )}
            >
                <ErrorBoundary>
                    <Carousel
                        sliders={sliders}
                        height={isMobile ? 168 : 130}
                        delay={delay}
                        withControls={withControls && !isMobile}
                        spaceBetween={isMobile ? 8 : 0}
                        centeredSlides={true}
                        slideClassName={containerStyles}
                        controlIconClassName="fill-grey-500"
                        onInit={onInit}
                        onTouchStart={loadPrevNextImages}
                        onSlideChangeTransitionStart={loadPrevNextImages}
                    />
                </ErrorBoundary>
            </div>
        </AnalyticAttrsCtx.Provider>
    );
};

export default memo(TopBannersCarousel);
