import { useCallback, useEffect, useRef, useState } from 'react';
import { Swiper } from 'swiper/types';

import {
    ACTIVE_MARKET_STATUSES,
    ACTIVE_SPORTEVENT_STATUSES,
} from 'app-constants';
import { useGetSportEventListByFilters } from 'components/betting/MatchList/getSportEventListByFilters.bet';
import { SportEventsInList } from 'types';
import { SportEventOrder, SportEventType } from 'types/gql.bet';
import { groupBannersByType } from 'utils';
import { Banner } from '../Banners/types';

const variables = {
    isTopMarkets: true,
    marketLimit: 1,
    order: SportEventOrder.RankStartTime,
    sportEventTypes: [SportEventType.Match],
    marketStatuses: ACTIVE_MARKET_STATUSES,
    marketStatusesForSportEvent: ACTIVE_MARKET_STATUSES,
    matchStatuses: ACTIVE_SPORTEVENT_STATUSES,
};

interface Input {
    isCmsBanner: boolean;
    isDesktop: boolean;
    initialLimit: number;
    maxCount: number;
    banners: Banner[];
}

interface Output {
    hasMatches: boolean;
    matches: SportEventsInList;
    onSlideChange: (swiper: Swiper) => void;
    isLoadEnd: boolean;
    isStartFetchMore: boolean;
    tournamentSportEventLoading: boolean;
    allowSlidePrev: boolean;
}

const useTournamentMatches = ({
    isCmsBanner,
    initialLimit,
    isDesktop,
    maxCount,
    banners,
}: Input): Output => {
    const [isLoadEnd, setIsLoadEnd] = useState<boolean>(false);
    const [isStartFetchMore, setIsStartFetchMore] = useState<boolean>(false);
    const matchFilteredRef = useRef<SportEventsInList>([]);
    const matchCountRef = useRef<number>(0);
    const swiperRef = useRef<Swiper | null>(null);
    const offsetRef = useRef<number>(0);

    const { sportEvents, tournamentIds } = groupBannersByType(banners);

    const sportEventsBannersLength = sportEvents.length;

    const tournamentMatchesLimit = maxCount - sportEventsBannersLength;

    const calculateInitialLimit = Math.max(
        initialLimit - sportEventsBannersLength,
        0
    );

    useEffect(() => {
        if (tournamentIds.length || !sportEventsBannersLength) return;

        matchFilteredRef.current = getMatches(sportEvents);
    }, [tournamentIds, sportEventsBannersLength, sportEvents]);

    const { loading: tournamentSportEventLoading, fetchMore } =
        useGetSportEventListByFilters(() => ({
            variables: {
                ...variables,
                offset: 0,
                limit:
                    calculateInitialLimit > tournamentMatchesLimit
                        ? tournamentMatchesLimit
                        : calculateInitialLimit,
                tournamentIds,
            },
            onCompleted: (data) => {
                const matchesLength = data.matches.sportEvents.length;
                matchFilteredRef.current = getMatches(data.matches.sportEvents);

                if (matchFilteredRef.current.length >= maxCount) {
                    setIsLoadEnd(true);

                    return;
                }

                if (!matchCountRef.current) {
                    matchCountRef.current = matchesLength;
                    offsetRef.current = matchesLength;
                }
            },
            returnPartialData: true,
            fetchPolicy: 'cache-and-network',
            skip:
                !isCmsBanner ||
                !tournamentIds.length ||
                !!sportEventsBannersLength ||
                tournamentMatchesLimit <= 0,
        }));

    const matchCount = matchFilteredRef.current.length;
    const fetchMoreOffset = offsetRef.current;

    const fetchMoreMemoized = useCallback(() => {
        fetchMore({
            variables: {
                ...variables,
                offset: fetchMoreOffset,
                limit: maxCount - matchCount,
            },
        })
            .then(({ data }) => {
                const dataLength = data.matches.sportEvents.length;

                if (!dataLength) {
                    setIsLoadEnd(true);

                    return;
                }

                offsetRef.current = fetchMoreOffset + dataLength;
            })
            .catch(console.error);
    }, [fetchMoreOffset, fetchMore, matchCount, maxCount]);

    useEffect(() => {
        if (!isStartFetchMore) {
            return;
        }

        if (sportEventsBannersLength && !isLoadEnd) {
            swiperRef.current?.slideNext();

            return;
        }

        if (isLoadEnd) {
            if (isDesktop || swiperRef.current?.destroyed) {
                return;
            }

            swiperRef.current?.slideNext();

            return;
        }

        fetchMoreMemoized();
    }, [
        sportEventsBannersLength,
        fetchMoreMemoized,
        isStartFetchMore,
        isLoadEnd,
        isDesktop,
    ]);

    const onSlideChange = useCallback(
        (swiper: Swiper) => {
            if (isStartFetchMore) {
                return;
            }

            swiperRef.current = swiper;

            if (!sportEventsBannersLength) {
                setIsStartFetchMore(true);
            }
        },
        [isStartFetchMore, sportEventsBannersLength]
    );

    const matches = matchFilteredRef.current.slice(0, maxCount);

    return {
        hasMatches: !!matchCount,
        onSlideChange,
        matches,
        isLoadEnd,
        isStartFetchMore,
        tournamentSportEventLoading,
        allowSlidePrev: !!sportEvents.length || isStartFetchMore,
    };
};

const getMatches = (sportEvents: SportEventsInList) => {
    return sportEvents.filter(
        ({ disabled, betStop, markets }) =>
            !disabled && !betStop && markets?.length
    );
};

export default useTournamentMatches;
